<template>
  <div class="person">
    <van-nav-bar :title="$t('个人信息')" left-arrow @click-left="onClickLeft" />
    <div class="photo">
      <img :src="user.src || imgSrc" alt="" />
      <van-uploader
        ref="vanUploader"
        v-model="fileList"
        multiple
        :after-read="afterRead"
        :max-count="1"
      />
    </div>
    <div style="padding: 0 0.3rem; margin-top: 1rem; text-align: center">
      <van-field
        v-model="user.text"
        :label="$t('昵称：')"
        clearable
        :placeholder="$t('请输入昵称')"
      />
      <div class="tab">
        <p class="title">{{ $t("性别：") }}</p>
        <van-radio-group v-model="user.sex" direction="horizontal" disabled>
          <van-radio name="0">{{ $t("保密") }}</van-radio>
          <van-radio name="1">{{ $t("男") }}</van-radio>
          <van-radio name="2">{{ $t("女") }}</van-radio>
        </van-radio-group>
      </div>
      <!-- <van-field
        v-model="user.phone"
        type="tel"
        label="电话："
        placeholder="请输入绑定手机号"
        clearable
        maxlength="11"
        show-word-limit
      /> -->
      <van-button round @click="submit" type="info">{{
        $t("保存")
      }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "person",
  data() {
    return {
      show: false,
      actions: [{ name: "拍照" }, { name: "从手机相册选择" }],
      imgSrc: require("../../assets/images/logo.png"),
      user: {},
      fileList: [],
    };
  },

  created() {
    let user = JSON.parse(sessionStorage.getItem("wxUser")) || "";
    if (this.user == "") {
      this.$toast.fail("登录信息超时，请重新登录");
      this.$router.push("/login");
      return;
    }
    this.user.text = user.nickName; // 用户名
    this.user.src = user.headimgUrl; // 头像URL
    this.user.sex = user.sex.toString(); //性别
    // this.user.phone = user.phone; //电话号
  },
  mounted() {
    if (!this.user == "") {
      this.$refs.vanUploader.$el.style.opacity = 0;
    }
  },
  methods: {
    // 回退
    onClickLeft() {
      this.$router.go("-1");
    },
    afterRead(file) {
      this.user.src = file.content;
    },
    submit() {
      // return;
      let params = new FormData();
      let user = JSON.parse(sessionStorage.getItem("wxUser"));
      params.append("userId", user.id);
      params.append("nickName", this.user.text);
      if (this.fileList.length) params.append("file", this.fileList[0].file);
      this.$axios.post("/wx/updateUserInfo", params).then((res) => {
        this.$toast.success(res.msg);
        // this.$router.push("/mine");
        sessionStorage.setItem("wxUser", JSON.stringify(res.data));
        this.user.src = res.data.headimgUrl;
        this.$forceUpdate();
      });
    },
  },
};

// (Integer userId, String nickName,MultipartFile file)
</script>

<style lang="less" scoped>
.person {
  padding-top: 0.92rem;
}
/deep/ .van-nav-bar {
  width: 100%;
  position: fixed;
  top: 0;
  i {
    font-size: 0.5rem;
    color: #000;
    margin-top: -0.01rem;
  }
  .van-nav-bar__title {
    font-weight: 500;
    font-size: 0.32rem;
    color: #000;
    margin-left: 0.9rem;
  }
}
.tab {
  position: relative;
  margin-top: 0.1rem;
  margin-bottom: -0.16rem;
  height: 0.7rem;
  line-height: 0.7rem;
  /deep/ .van-radio-group--horizontal {
    padding-left: 1.6rem;
    height: 100%;
  }
  .title {
    position: absolute;
    left: 0.28rem;
    font-size: 0.36rem;
  }
}

.photo {
  text-align: center;
  margin-top: 1.2rem;
  position: relative;
  img {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  i {
    font-size: 0.34rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 58%;
  }
}
/deep/ .van-cell {
  align-items: center;
  padding: 0.3rem 0.32rem;
  font-size: 0.36rem;
  .van-field__label {
    width: 3.2em;
  }
}
/deep/ .van-cell::after {
  border: 1px solid rgba(0, 0, 0, 0.20000000298023224);
}
/deep/ .van-cell:nth-child(3)::after {
  border: none;
}
/deep/ .van-button {
  width: 70%;
  height: 0.7rem;
  border: none;
  background: #14abfb;
  font-size: 0.3rem;
  margin-top: 20vh;
}
</style>